import {
  faDownload,
  faSort,
  faSortDown,
  faSortUp
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { saveAs } from 'file-saver';
import { observer } from 'mobx-react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useCallback, useContext, useMemo, useState } from 'react';
import {
  Button,
  OverlayTrigger,
  Spinner,
  Table,
  Tooltip
} from 'react-bootstrap';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable
} from 'react-table';
import { storesContext } from '../../stores/storesContext';
import TablePagination from '../helpers/TablePagination';
import { errorToast } from '../helpers/toasts/ToastUtils';

const ReportTable = ({ reports }) => {
  const userStore = useContext(storesContext);
  const propertiesStore = userStore.propertiesStore;
  // Keeps track the the report being downloaded. If all reports are being downloaded,
  // the state value is set to 'all'. If no reports are being downloaded then null.
  const [downloading, setDownloading] = useState(null);

  const downloadReport = useCallback(
    async (report) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_PROPERTIES_API_URL}api/fruition/crop-scanning/reports/${report.key}`,
          {
            headers: {
              Authorization: `Bearer ${userStore.bearerToken}`,
              Accept: 'application/pdf',
              seasonalDatabase: userStore.selectedDatabase
            }
          }
        );
        if (!response.ok) {
          const status = response.status;
          if (status === 404) {
            errorToast('Report not found.', 'download-report-error');
          } else {
            errorToast('Failed to download report.', 'download-report-error');
          }
          return;
        }
        const blob = await response.blob();
        saveAs(
          blob,
          `${moment(report.dt).format('YYYYMMDD')}-${
            propertiesStore.selectedProperty.identifier
          }-${propertiesStore.selectedProperty.name}-${report.blockCode}-${
            report.scanType
          }-${report.stage}-${report.reportType}-${report.pdfType}`
        );
      } catch (err) {
        console.error(err);
        errorToast('Failed to download report.', 'download-report-error');
      }
    },
    [
      propertiesStore.selectedProperty.identifier,
      propertiesStore.selectedProperty.name,
      userStore.bearerToken,
      userStore.selectedDatabase
    ]
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'dt',
        Cell: ({ value }) => {
          return moment(value).format('YYYY-MM-DD');
        }
      },
      {
        Header: 'Block Code',
        accessor: 'blockCode'
      },
      {
        Header: 'Scan Type',
        accessor: 'scanType'
      },
      {
        Header: 'Stage',
        accessor: 'stage'
      },
      {
        Header: 'Report Type',
        accessor: 'reportType'
      },
      {
        Header: 'PDF Type',
        accessor: 'pdfType'
      },
      {
        id: 'download',
        sortable: false,
        Header: ({ page }) => (
          <OverlayTrigger
            placement='top'
            overlay={<Tooltip>Download all reports from this table.</Tooltip>}
          >
            <Button
              onClick={async () => {
                setDownloading('all');
                await Promise.all(
                  page.map((row) => {
                    return downloadReport(row.original);
                  })
                );
                setDownloading(null);
              }}
              variant='link'
              disabled={reports.length === 0}
            >
              {downloading === 'all' ? (
                <Spinner
                  animation='border'
                  variant='primary'
                  size='sm'
                  style={{ marginLeft: '2px', marginRight: '3px' }}
                />
              ) : (
                <FontAwesomeIcon icon={faDownload} size='lg' />
              )}
            </Button>
          </OverlayTrigger>
        ),
        Cell: ({ row }) => (
          <Button
            onClick={async () => {
              setDownloading(row.original.key);
              await downloadReport(row.original);
              setDownloading(null);
            }}
            variant='link'
            className='text-dark'
          >
            {downloading === row.original.key ? (
              <Spinner
                animation='border'
                variant='primary'
                size='sm'
                style={{ marginLeft: '2px', marginRight: '3px' }}
              />
            ) : (
              <FontAwesomeIcon icon={faDownload} size='sm' />
            )}
          </Button>
        )
      }
    ],
    [downloadReport, downloading, reports.length]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data: reports
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <div>
      <Table responsive {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  {column.canSort ? (
                    <span className='px-2'>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FontAwesomeIcon icon={faSortDown} />
                        ) : (
                          <FontAwesomeIcon icon={faSortUp} />
                        )
                      ) : (
                        <FontAwesomeIcon icon={faSort} />
                      )}
                    </span>
                  ) : null}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                className={
                  row.original.pdfType === 'Geo pdf' ? 'text-info' : ''
                }
              >
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
      <TablePagination
        pageCount={pageCount}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        pageSize={pageSize}
        gotoPage={gotoPage}
        canPreviousPage={canPreviousPage}
        previousPage={previousPage}
        canNextPage={canNextPage}
        nextPage={nextPage}
        setPageSize={setPageSize}
      />
    </div>
  );
};

ReportTable.propTypes = {
  reports: PropTypes.array.isRequired,
  blockName: PropTypes.string
};

export default observer(ReportTable);

